
import ApexCharts from 'apexcharts'
import flatpickr from "flatpickr";

//get #compare-dates
const compareDates = document.getElementById('compare-dates');
if (compareDates) {
    let beginning = '';
    let today = '';
    //get selcted value from #start-date
    const selectedDate = document.getElementById('start-date').value;
    [beginning, today] = selectedDate.split('|');
    
    document.querySelector('.range-start').value = `${beginning} to ${today}`;
    setTitleDate('#title-date', beginning, today);
    
    renderOrderChart();
    
    const fpStart = initFlatpickr(".range-start", '#start-date-wrapper');
    const fpEnd = initFlatpickr(".range-end", '#end-date-wrapper');
    
    document.querySelector('#start-date').addEventListener('change', (e) => handleDateChange(e, fpStart, '#title-date'));
    document.querySelector('#end-date').addEventListener('change', (e) => handleDateChange(e, fpEnd, '#title-date-compare'));
    document.getElementById('group-by').addEventListener('change', renderOrderChart);
    document.getElementById('select-by').addEventListener('change', renderOrderChart);
    
    const filterByEvents = document.getElementById('filter-by-events');
    if (filterByEvents) {
        filterByEvents.addEventListener('change', renderOrderChart);
    }
    const filterByRole = document.getElementById('filter-by-role');
    if (filterByRole) {
        filterByRole.addEventListener('change', renderOrderChart);
    }
    document.querySelectorAll('.graph-type').forEach(label => {
        label.addEventListener('click', renderOrderChart);
    });

}

function renderOrderChart() {
    const rangeStart = document.querySelector('.range-start').value;
    const rangeEnd = document.querySelector('.range-end').value;
    const table = document.getElementById('table').value;
    const url = compareDates.getAttribute('action');
    const groupBy = document.getElementById('group-by').value;
    const selectBy = document.getElementById('select-by').value;
    const graphType = document.querySelector('input[name="graph"]:checked').value;
    //get #filter-by-events value if exists
    const filterByEvents = document.getElementById('filter-by-events');
    let filterByEventsValue = '';
    if (filterByEvents) {
        filterByEventsValue = filterByEvents.value;
    }

    const filterByRole = document.getElementById('filter-by-role');
    let filterByRoleValue = '';
    if (filterByRole) {
        filterByRoleValue = filterByRole.value;
        console.log(filterByRoleValue);
    }

    const options = {
        chart: {
            foreColor: '#9ba7b2',
            height: 360,
            type: graphType,
            animations: {
                enabled: true
            },
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                top: 3,
                left: 14,
                blur: 4,
                opacity: 0.10,
            },

        },
        stroke: {
            width: [5, 5],
            curve: 'smooth',
            dashArray: [0, 5]
        },
        dataLabels: {
            enabled: false
        },
        series: [],
        title: {
            text: selectBy,
        },
        noData: {
            text: 'Loading...'
        },
        fill: {
           
        },
        markers: {
            size: 4,
            colors: ["#923eb9", "#fa5661"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            }
        },
        colors: ["#fa5661", "#cf7477"],

    }

    var chart = new ApexCharts(document.querySelector('#orders-chart'), options);

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
           rangeStart: rangeStart,
            rangeEnd: rangeEnd,
            table: table,
            groupBy: groupBy,
            selectBy: selectBy,
            eventId: filterByEventsValue,
            role: filterByRoleValue
        })
    })
        .then(response => response.json())
        .then(data => {
            chart.render();
            chart.updateSeries([{
                name: 'Current',
                data: data.original
            },
            {
                name: 'Previous',
                data: data.compare
            }])

            //get  id="orders-table"
            const ordersTable = document.getElementById('orders-diff-table');
            //clear table tbody
            ordersTable.innerHTML = '';

            //create table
            data.original.forEach((item, index) => {
                //if data.compare[index] is undefined, set it to 0
                if (data.compare[index] === undefined) {
                    data.compare[index] = { y: 0 };
                }
                let diff = item.y - data.compare[index].y;
                diff = renderDiff(diff);
                //if${data.compare[index].x} !== ${item.x} set it to 
                let compareX = data.compare[index].x !== item.x ? `<i>${data.compare[index].x}</i>` : '';
                const tr = document.createElement('tr');
                tr.innerHTML = `
                <td>${item.x}</td>
                <td>${compareX}</td>
                <td>${item.y}</td>
                <td>${data.compare[index].y}</td>
                <td>${diff}</td>
                `;
                ordersTable.appendChild(tr);
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });


}

function setTitleDate(selector, beginning, today) {
    const titleDate = document.querySelector(selector);
    //format date to dd/mm/yyyy
    beginning = beginning.split('-').reverse().join('/');
    today = today.split('-').reverse().join('/');

    titleDate.innerHTML = beginning + ' - ' + today;
}

function initFlatpickr(selector, positionElementSelector) {
    return flatpickr(selector, {
        mode: "range",
        positionElement: document.querySelector(positionElementSelector),
        onChange: function (selectedDates, dateStr, instance) {
            renderOrderChart();
        },
        locale: {
            firstDayOfWeek: 1
        }
    });
}

function handleDateChange(e, fp, titleSelector) {
    if (e.target.value === 'all') {
        fp.setDate([beginning, today], true);
    } else if (e.target.value === 'custom') {
        fp.open();
    } else if (e.target.value !== '') {
        const dates = e.target.value.split('|');
        fp.setDate([dates[0], dates[1]], true);
        setTitleDate(titleSelector, dates[0], dates[1]);
    } else {
        fp.clear();
    }
}

function renderDiff(diff) {
    let diffClass = '';
    let arrow = '';

    if (diff < 0) {
        diffClass = 'text-danger';
        arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
      </svg>`;
    } else if (diff === 0) {
        diffClass = 'text-muted';
        arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/>
      </svg>`;
    } else {
        diffClass = 'text-success';
        arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
      </svg>`;
    }

    return `<span class="${diffClass}">${arrow} ${diff} </span>`;
}