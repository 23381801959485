import { initHtmlEditor, destroyHtmlEditor } from "./custom-functions";
const emailsSend = document.getElementById('emails-send');
if (emailsSend) {
    
    //get #send-email-next
    const shortcuts = document.getElementById('shortcuts');
    const sendEmailNext = document.getElementById('send-email-next');
    const firstStep = document.getElementById('first-step');
    const secondStep = document.getElementById('second-step');
    const backToStep1 = document.getElementById('send-email-prev-1');
    sendEmailNext.addEventListener('click', () => {
        //get all .first-step and validate
        const firstStepsInputs = document.querySelectorAll('.first-step');
        let validate = 0;
        firstStepsInputs.forEach((item) => {
            if (item.required && item.value === '') {
                item.classList.add('is-invalid');
                validate++;
            } else {
                item.classList.remove('is-invalid');
            }
        });
        if (validate === 0) {
            //hide first step
            firstStep.classList.add('d-none');
            shortcuts.classList.add('d-none');
            //show second step
            secondStep.classList.remove('d-none');
            //get #select-template value
            fetchTemplate(document.getElementById('select-template').value);
        }
    });

    backToStep1.addEventListener('click', () => {
        //hide second step
        secondStep.classList.add('d-none');
        shortcuts.classList.remove('d-none');
        //show first step
        firstStep.classList.remove('d-none');
        destroyHtmlEditor();
    });


    document.getElementById('email-preview-btn-it').addEventListener('click', function () {
        previewEmail('it');
    });

    document.getElementById('email-preview-btn-de').addEventListener('click', function () {
        previewEmail('de');
    });
}

const fetchTemplate = (id) => {
 
    fetch(`/email-templates/${id}/fetch`,
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        }
    )
        .then(response => response.json())
        .then(result => {
            if (result.success) {
                for (let [key, value] of Object.entries(result.success)) {
                    let input = document.getElementById(`${key}`);

                    input.value = value;
                }
                initHtmlEditor();
            }
        });

}


function previewEmail(language) {
    var form = document.getElementById('emails-send');
    var formData = new FormData(form);
    formData.append('language', language);

    fetch('/email-preview', {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: formData
    })
        .then(response => response.text())
        .then(data => {
            var preview = document.getElementById('email-preview-body');
            preview.innerHTML = data;
        });
}
