
import ApexCharts from 'apexcharts'
import flatpickr from "flatpickr";

//check if homepage
const dashboard = document.getElementById('dashboard');
if (dashboard) {
    const beginning = new Date(new Date().setDate(new Date().getDate() - 6000)).toISOString().slice(0, 10);
    const today = new Date().toISOString().slice(0, 10);

    //set range date  .range with beginning|today
    document.querySelector('.range').value = beginning + ' to ' + today;

    const fp = flatpickr(".range", {
        mode: "range",
        positionElement: document.querySelector('#set-date-wrapper'),
        //defaultDate: [beginning, today],
        onChange: function (selectedDates, dateStr, instance) {
            renderChart();
        }
    });

    //get #set-date
    const setDate = document.querySelector('#set-date');
    //on change #set-date
    setDate.addEventListener('change', (e) => {
        //if value == all
        if (e.target.value == 'all') {
            fp.setDate([beginning, today], true);
        } else if (e.target.value == 'custom') {
            //open flatpickr
            fp.open();
        } else {
            const dates = e.target.value.split('|');
            fp.setDate([dates[0], dates[1]], true);
        }


    });

    var optionsStackedBar = {
        series: [],
        chart: {
            foreColor: '#9ba7b2',
            type: 'bar',
            stacked: true,
            //height: 'auto',
            //stackType: '100%',
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                distributed: false,
                barHeight: '100%',
                endingShape: 'rounded'
            }
        },
        colors: ["green", "red"],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            },

        },

        stroke: {
            width: 1,
            colors: ['#fff']
        },


        xaxis: {
            categories: [],
        }
    };

    // chart 1
    var optionsLine = {
        series: [],
        chart: {
            foreColor: '#9ba7b2',
            height: 360,
            type: 'line',
            animations: {
                enabled: true
            },
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                top: 3,
                left: 14,
                blur: 4,
                opacity: 0.10,
            },

        },
        stroke: {
            width: 5,
            curve: 'smooth'
        },

        title: {
            text: 'Loading...',
            align: 'left',
            style: {
                fontSize: "16px",
                color: '#666'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                gradientToColors: ['#923eb9'],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
            },
        },
        markers: {
            size: 4,
            colors: ["#923eb9"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            }
        },
        colors: ["#923eb9"],
        yaxis: {
            title: {
                text: '',
            },
        },
    };

    var optionsPie = {
        series: [],
        chart: {
            foreColor: '#9ba7b2',
            height: 380,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '80%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w) {
                                // Qui puoi restituire il totale dei tuoi dati
                                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                            }
                        }
                    }
                }
            }
        },
        legend: {
            position: 'bottom'
        },
        colors: ["#923eb9", "#f73757", "#18bb6b", "#32bfff", "#ffab4d"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    height: 320
                },
            }
        }]
    };

    var optionsBar = {
        series: [],
        chart: {
            foreColor: '#9ba7b2',
            type: 'bar',
            height: 'auto',
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                barHeight: '100%',
                endingShape: 'rounded'
            }
        },
        colors: ["#923eb9", "#f73757", "#18bb6b", "#32bfff", "#ffab4d"],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            },

        },

        stroke: {
            width: 1,
            colors: ['#fff']
        },


        xaxis: {
            categories: [],
        }
    };

    //get all .ajax-chart
    const ajaxCharts = document.querySelectorAll('.ajax-chart');
    if (ajaxCharts.length > 0) {
        renderChart();

    }

    //on change #type
    const selectFilters = document.querySelectorAll('.select-filters');
    selectFilters.forEach(selectFilter => {
        selectFilter.addEventListener('change', (e) => {
            console.log(e.target.value);
            //get data-id
            const id = e.target.dataset.id;
            const name = e.target.dataset.name;
            //get element with id
            const element = document.getElementById(id);
            //if select value empty set element data-filters to ''
            if (e.target.value == '') {
                element.dataset.filters = '';
            } else {
                element.dataset.filters = name + '=' + e.target.value;
            }
            renderChart(id);
        });
    });

    function renderChart(chartId = null) {
        ajaxCharts.forEach(ajaxChart => {
            const id = ajaxChart.id;
            //if chartId not null and chartId != id return
            if (chartId != null && chartId != id) {
                return;
            }
            //get .range input value
            const range = document.querySelector('.range');
            let from = '';
            let to = '';
            //if not empty ranne set from to
            if (range.value != '') {
                from = range.value.split(' to ')[0];
                to = range.value.split(' to ')[1];
            }

            //if data-filter exists get value 
            let filters = '';
            if (ajaxChart.dataset.filters) {
                filters = ajaxChart.dataset.filters;
            }
            const numeric = ajaxChart.dataset.numeric;
            const style = ajaxChart.dataset.style;
            const table = ajaxChart.dataset.table;
            const title = ajaxChart.dataset.title;
            const group = ajaxChart.dataset.group;
            const chart = new ApexCharts(document.querySelector(`#${id}`), getOption(style));


            //get #type value

            //fetch url widget with crsf
            //get csrf from Media
            const url = 'widget'
            const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const data = {
                'from': from,
                'to': to,
                'numeric': numeric,
                'style': style,
                'table': table,
                'group': group,
                'filters': filters
            };
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrf
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    chart.render();
                    //create chart
                    if (style == 'pie') {
                        chart.updateSeries(
                            data.series
                        )
                        //update labels
                        chart.updateOptions({
                            labels: data.labels,
                            title: {
                                text: title,
                                align: 'center',
                                style: {
                                    fontSize: "16px",
                                    color: '#666'
                                }
                            }
                        }, true)
                    } if (style == 'stacked-bar') {

                        chart.updateSeries([{
                            name: numeric,
                            data: data[0],
                            color: "#00cc00",
                        },
                        {
                            name: 'sold',
                            data: data[1],
                            color: "#ff0000",
                        }])
                        //update chart title
                        chart.updateOptions({
                            title: {
                                text: title,
                                align: 'left',
                                style: {
                                    fontSize: "16px",
                                    color: '#666'
                                }
                            }
                        }, true)
                    }

                    else {
                        //sum all values
                        let sum = 0;
                        data.forEach(item => {
                            sum += Math.round(Number(item.y));
                        });
                        if (numeric == 'amount') {
                            sum = sum + '€';
                        }
                        chart.updateSeries([{
                            name: numeric,
                            data: data
                        }])
                        //update chart title
                        chart.updateOptions({
                            title: {
                                text: title + ' - ' + sum,
                                align: 'left',
                                style: {
                                    fontSize: "16px",
                                    color: '#666'
                                }
                            }
                        }, true)

                    }



                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        });
    }

    //select all .widget-destroy
    const widgetsDestroy = document.querySelectorAll('.widget-destroy');
    //on click .widget-destroy
    widgetsDestroy.forEach(widget => {
        widget.addEventListener('click', (e) => {
            e.preventDefault();
            if (!confirm('Vuoi cancellare questo grafico?')) {
                return;
            }
            const id = e.target.dataset.id;
            //get csrf from Media
            const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            fetch(`widgets/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrf
                },
            })
                .then(response => response.json())
                .then(data => {
                    //remove widget parent
                    e.target.parentNode.remove();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        });
    });
}


function getOption(style) {
    switch (style) {
        case 'line':
            return optionsLine;
        case 'pie':
            return optionsPie;
        case 'bar':
            return optionsBar;
        case 'stacked-bar':
            return optionsStackedBar;
        default:
            return optionsLine;
    }
}