// get #widget-creator
const widgetCreator = document.getElementById('widget-creator');
if (widgetCreator) {
    //get #table select
    //get #style
    const styleSelect = widgetCreator.querySelector('#style');
    const tableSelect = widgetCreator.querySelector('#table');
    const fieldsSelect = widgetCreator.querySelector('#fields');
    const addFilter = widgetCreator.querySelector('#add-filter');
    let fetchedData = {};

    //on change style
    styleSelect.addEventListener('change', (event) => {
        //remove border-danger from styleSelect
        styleSelect.classList.remove('border-danger');
        //reset tableSelect option to default
        tableSelect.value = '';
        //empty fieldsSelect
        fieldsSelect.innerHTML = '';
        //hide addFilter
        addFilter.classList.add('d-none');
        //disable submit button
        const submitButton = widgetCreator.querySelector('button[type="submit"]');
        submitButton.setAttribute('disabled', 'disabled');
    });

    //on change event
    tableSelect.addEventListener('change', (event) => {
        //get selected option value
        const table = event.target.value;

        const style = styleSelect.value;
        if (style === '') {
            //create round border on style select
            styleSelect.classList.add('border-danger');
            return;
        }

        fieldsSelect.innerHTML = '';
        addFilter.classList.add('d-none');
        fetchedData = {};

        fetch('/widgets/fetch-table-fields', {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                table,
                style
            })
        })
            .then(response => response.json())
            .then(data => {
                fetchedData = data;
                //show addFilter
                addFilter.classList.remove('d-none');
                //remove all options
                //if style is line
                if (style === 'line') {
                    const yAxis = data['numeric'];
                    createSelect(yAxis, 'numeric', 'Valori', fieldsSelect);

                } else {
                    const yAxis = data['numeric'];
                    createSelect(yAxis, 'numeric', 'Valori', fieldsSelect);
                    const groupBy = data['group_by'];
                    createSelect(groupBy, 'group_by', 'GroupBy', fieldsSelect);
                }

            })
            .catch(error => console.log(error));
    });

    //on click addFilter add a new select with options from data['filters']
    addFilter.addEventListener('click', (event) => {
        event.preventDefault();
        //get data['filters']
        const filters = fetchedData['filters'];
        //create select with options from data['filters']
        createSelect(filters, 'filters[]', 'Filtri', fieldsSelect);
    });


    fieldsSelect.addEventListener('change', (event) => {
        const target = event.target;
        if (target.classList.contains('Filtri')) {

            if (target.value === '') {
                //destry parent parent
                target.parentElement.parentElement.remove();
                return;
            }
            //rimuove seconda e terza colonna se ci sono
            const parent = target.parentElement.parentElement;
            while (parent.children.length > 1) {
                parent.removeChild(parent.lastChild);
            }
            //fetch data
            const table = tableSelect.value;
            const field = target.value;
            fetch('/widgets/field-distinct-values', {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    table,
                    field
                })
            })
                .then(response => response.json())
                .then(data => {
                    //count #fields children
                    const fieldsChildren = fieldsSelect.children.length - 1;
                    //get operatorsForFields[field].operator and type and create select with operators and input with type
                    const operators = operatorsForFields[field].operator;
                    const type = operatorsForFields[field].type;
                    //create select with operators
                    createSelect(operators, 'filters[' + fieldsChildren + '][' + field + '][operator]', field, target.parentElement.parentElement, 'col');
                    //create input with type
                    //if type is select create select with options from data
                    if (type === 'select') {
                        createSelect(data, 'filters[' + fieldsChildren + '][' + field + '][value]', field, target.parentElement.parentElement, 'col');
                    } else {
                        createInput(type, 'filters[' + fieldsChildren + '][' + field + '][value]', field, target.parentElement.parentElement);
                    }

                })
                .catch(error => console.log(error));
        }

        //if .numeric enable submit button
        if (target.classList.contains('Valori')) {
            if (target.value === '') {
                //get submit button with type submit
                const submitButton = widgetCreator.querySelector('button[type="submit"]');
                submitButton.setAttribute('disabled', 'disabled');
            } else {
                //get submit button with type submit
                const submitButton = widgetCreator.querySelector('button[type="submit"]');
                submitButton.removeAttribute('disabled');
            }
        }
    });
}

//create a function to create a select with options from data
const createSelect = (data, name, label, parent, inside = '') => {
    const select = document.createElement('select');
    select.setAttribute('name', name);
    select.setAttribute('class', 'form-control');
    select.setAttribute('required', 'required');
    select.classList.add(label);
    //create default option
    let defaultOption = document.createElement('option');
    defaultOption.setAttribute('value', '');
    defaultOption.innerHTML = `Scegli ${label}`;
    //append default option to select
    select.appendChild(defaultOption);
    //loop through data and create option for each value
    for (let [key, value] of Object.entries(data)) {
        const option = document.createElement('option');
        option.setAttribute('value', key);
        option.innerHTML = value;
        select.appendChild(option);
    }
    //add label to select
    const inputLabel = document.createElement('label');
    inputLabel.innerHTML = label;
    //create div with class mb-3
    const row = document.createElement('div');
    row.classList.add('mb-3');
    row.classList.add('row');
    const col = document.createElement('div');
    col.classList.add('col-md-4');
    //append select to div
    if (inside === 'col') {
        col.appendChild(inputLabel);
        col.appendChild(select);
        parent.appendChild(col);
    } else {
        row.appendChild(col);
        col.appendChild(inputLabel);
        col.appendChild(select);
        //append label and div to form

        parent.appendChild(row);
    }
}

function createInput(type, name, className, element) {
    const input = document.createElement('input');
    input.setAttribute('type', type);
    input.setAttribute('name', name);
    input.setAttribute('class', 'form-control');
    input.setAttribute('required', 'required');
    input.classList.add(className);
    //create div with class mb-3
    const div = document.createElement('div');
    div.classList.add('col-md-4');

    const inputLabel = document.createElement('label');
    inputLabel.innerHTML = className;
    div.appendChild(inputLabel);
    div.appendChild(input);
    element.appendChild(div);
}

const operatorsForFields = {
    'source': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'type': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'amount': {
        'type': 'number',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'quantity': {
        'type': 'number',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'payment_method': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'categories': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
            'is null': 'is null',
            'is not null': 'is not null'
        }
    },
    'status': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
            'is null': 'is null',
            'is not null': 'is not null'
        }
    },
    'receipt_status': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
            'is null': 'is null',
            'is not null': 'is not null'
        }
    },
    'created_at': {
        'type': 'date',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'dob': {
        'type': 'date',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'city': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'state': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'country': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'agree_newsletter': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'keywords_flatted': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'title': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'type': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'place_id': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'categories': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'tags': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'series_id': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'nationality': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'gender': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'start_date': {
        'type': 'date',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'end_date': {
        'type': 'date',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'city': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'society': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'role': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'size': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'gloves_size': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'language': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'medical_certificate_status': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'event_id': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'camp_place': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },
    'stock': {
        'type': 'number',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'total_sales': {
        'type': 'number',
        'operator': {
            '=': '=',
            '<': '<',
            '>': '>',
            '<=': '<=',
            '>=': '>=',
        }
    },
    'stock_status': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },

    'name': {
        'type': 'select',
        'operator': {
            '=': '=',
            '!=': '!=',
        }
    },

};