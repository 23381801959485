
/* ===== Responsive Sidepanel ====== */
const sidePanelToggler = document.getElementById('sidepanel-toggler');
const sidePanel = document.getElementById('app-sidepanel');
const sidePanelDrop = document.getElementById('sidepanel-drop');
const sidePanelClose = document.getElementById('sidepanel-close');
if(sidePanel) {
window.addEventListener('load', function () {
    responsiveSidePanel();
});

window.addEventListener('resize', function () {
    responsiveSidePanel();
});


function responsiveSidePanel() {
    let w = window.innerWidth;
    if (w >= 1200) {
        // if larger 
        //console.log('larger');
        sidePanel.classList.remove('sidepanel-hidden');
        sidePanel.classList.add('sidepanel-visible');

    } else {
        // if smaller
        //console.log('smaller');
        sidePanel.classList.remove('sidepanel-visible');
        sidePanel.classList.add('sidepanel-hidden');
    }
};

sidePanelToggler.addEventListener('click', () => {
    if (sidePanel.classList.contains('sidepanel-visible')) {
        sidePanel.classList.remove('sidepanel-visible');
        sidePanel.classList.add('sidepanel-hidden');

    } else {
        sidePanel.classList.remove('sidepanel-hidden');
        sidePanel.classList.add('sidepanel-visible');
    }
});



sidePanelClose.addEventListener('click', (e) => {
    e.preventDefault();
    sidePanelToggler.click();
});

sidePanelDrop.addEventListener('click', (e) => {
    sidePanelToggler.click();
});


}

/**************
* Menu
* 
* */

//get all .nav-link inside #app-sidepanel
let navLinks = document.querySelectorAll('#app-sidepanel .nav-link');
if (navLinks.length > 0) {
    //loop through navLinks
    navLinks.forEach(navLink => {
        //get href from navLink
        let href = navLink.getAttribute('href');
        //get current url
        let url = window.location.href;
        //remove other .nav-link active class
        navLink.classList.remove('active');
        //if href is in url add active class to navLink

        if (url.includes(href) && href != '' && href != '#') {
            navLink.classList.add('active');
        }
    });
}
//get all .submenu-link
let submenuLinks = document.querySelectorAll('.submenu-link');
if (submenuLinks.length > 0) {
    submenuLinks.forEach(submenuLink => {
        let href = submenuLink.getAttribute('href');
        let url = window.location.href;
        submenuLink.classList.remove('active');
        if (url.includes(href) && href != '' && href != '#') {
            submenuLink.classList.add('active');
            //get submenu
            let submenu = submenuLink.parentElement.parentElement.parentElement;
            //add show class to submenu
            submenu.classList.add('show');
            //get previousElementSibling to submenu
            let submenuParent = submenu.previousElementSibling;
            submenuParent.classList.add('active');
        }
    });
}