// Purpose: To handle the order status change functionality
import { showErrorToast, showSuccessToast, handleSubmit} from './custom-functions';
var orderActions = document.querySelectorAll('.order-action');

if (orderActions.length > 0) {
    orderActions.forEach(function (orderAction) {
        orderAction.addEventListener('click', function () {
            //get the order_id from the data-order-id attribute
            showFixedSpinner();
            const status = this.getAttribute('data-status');
            const url = this.getAttribute('data-action');
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            const data = {
                status: status
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(function (data) {
                    //if the request was successful, reload the page
                    if (data.success) {
                        setTimeout(function () {
                            window.location.reload();
                        }, 1000);
                    } else {
                        showErrorToast(data.message);
                        hideFixedSpinner();
                    }
                })
                .catch(function (error) {
                    hideFixedSpinner();
                    showErrorToast('An error occurred. Please try again later.');

                    console.log(error);
                });
        });
    });
}

//get #changeEventForm
const changeEventForm = document.getElementById('changeEventForm');
if (changeEventForm) {
    const changeParticipantEvent = document.querySelectorAll('.change-participant-event');
    const submit = document.getElementById('changeEventFormSubmit');
    if (changeParticipantEvent.length > 0) {
        changeParticipantEvent.forEach(changeParticipant => {
            changeParticipant.addEventListener('click', (e) => {
                e.preventDefault();
                const id = changeParticipant.getAttribute('data-id');
                //set action to //participants/{id}/change-event in changeEventForm
                changeEventForm.setAttribute('action', `/participants/${id}/change-event`);
                document.getElementById('new-event').value = '';
                document.getElementById('new-product').innerHTML = '';
                submit.setAttribute('disabled', true);
            });
        });
    }
    const newEvent = document.getElementById('new-event');
    //on change newEvent
    newEvent.addEventListener('change', (e) => {
        e.preventDefault();

        const products = document.getElementById('new-product');
        //get value
        const event = newEvent.value;
        //ignore first option
        if (event === '') {
            products.innerHTML = '';
            //add d-none to new-product
            products.classList.add('d-none');
            //disable submit
            submit.setAttribute('disabled', true);
            return;
        }
        //fetch /events/{event}/products
        fetch(`/events/${event}/products`)
            .then(response => response.json())
            .then(result => {
                products.classList.remove('d-none');
                //remove all options
                products.innerHTML = '';
                //add options
                result.forEach(product => {
                    let option = document.createElement('option');
                    option.value = product.id;
                    option.text = product.name;
                    products.add(option);
                });
                //enable submit
                submit.removeAttribute('disabled');
            })
            .catch(error => console.error('error:', error));
    });


    //on submit
    changeEventForm.addEventListener('submit', (event) => {
        event.preventDefault();
        let formData = new FormData(changeEventForm);

        console.log(formData);
        let url = changeEventForm.getAttribute('action');
        showFixedSpinner();
        //crsf token
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    window.location.reload();
                } else {
                    showErrorToast();
                }
                hideFixedSpinner();
            })
            .catch(error => {
                hideFixedSpinner();
                showErrorToast()
            });
    });

}

///get #refundForm
const refundCollapse = document.getElementById('refundCollapse');
if(refundCollapse){
    const refundForm = document.getElementById('refundForm');
    const lineItems = document.getElementById('line_items');
    const refundAmount = document.getElementById('refund_amount');
    //on change lineItems
    lineItems.addEventListener('change', (e) => {
        e.preventDefault();
        //get value
        const value = lineItems.value;
        refundAmount.value = '';
        //get #refundAmount
        if(value === ''){
            refundAmount.parentElement.classList.add('d-none');
            return;
        }
        if(value === 'custom'){
        
            refundAmount.parentElement.classList.remove('d-none');
            return;
        } 
        //get data-price
        const price = lineItems.options[lineItems.selectedIndex].getAttribute('data-price');
        //set value to value
        refundAmount.value = price;
    });

    //on submit
    refundForm.addEventListener('submit', (event) => {
        handleSubmit(event, refundForm, null, true);
    });
}


function showFixedSpinner() {
    var spinner = document.getElementById('fixed-spinner');
    spinner.classList.remove('d-none');
}

//hide #fixed-spinner
function hideFixedSpinner() {
    var spinner = document.getElementById('fixed-spinner');
    spinner.classList.add('d-none');
}