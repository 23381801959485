import { showErrorToast } from './custom-functions';

function exportData(buttonId, formId, url) {
    const button = document.getElementById(buttonId);
    if (button) {
        button.addEventListener('click', () => {
            //disable button
            button.setAttribute('disabled', true);
            const exportSpinner = document.getElementById('exportSpinner');
            exportSpinner.classList.remove('d-none');

            //get form
            let form = document.getElementById(formId);
            //get all inputs and select values
            let inputs = form.querySelectorAll('input, select');
            let data = {};
            inputs.forEach(input => {
                data[input.name] = input.value;
                console.log(input.name, input.value);
            });
            //crsf token
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {
                    var a = document.createElement("a");
                    a.href = result.file;
                    a.download = result.name;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    button.removeAttribute('disabled');
                    exportSpinner.classList.add('d-none');
                })
                .catch(error => {
                    showErrorToast();
                    //enable button
                    button.removeAttribute('disabled');
                    exportSpinner.classList.add('d-none');
                });
        });
    }
}

exportData('export-participants', 'participants-filters-form', '/participants/export');
exportData('export-orders', 'orders-filters-form', '/orders/export');
exportData('export-sizes', 'sizes-filters-form', '/sizes/export');
exportData('export-gloves-sizes', 'gloves-sizes-filters-form', '/gloves-sizes/export');
exportData('export-sizes-goalkeepers', 'sizes-goalkeepers-filters-form', '/goalkeepers-sizes/export');
exportData('export-compare', 'compare-dates', '/stats/export');