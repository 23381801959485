/**
 * Initializes the offcanvas functionality.
 */
export const initOffcanvas = () => {
    //get all .offcanvas
    let btns = document.querySelectorAll('.btn-offcanvas');
    btns.forEach(btn => {
        // on click element show offcanvas where id = element.dataset.target
        btn.addEventListener('click', (event) => {
            event.preventDefault();
            destroyHtmlEditor();
            let form = document.getElementById(btn.dataset.target).querySelector('form');
            form.reset();
            //remove id input hidden if any
            let oldInputId = document.getElementById('id');
            if (oldInputId) {
                oldInputId.remove();
            }
            let submitButton = form.querySelector('.btn-label');
            submitButton.innerText = 'Crea';
            let div = document.getElementById(btn.dataset.target);
            //check if offcanvas exist 
            if (div.classList.contains('offcanvas')) {
                let offcanvas = new bootstrap.Offcanvas(div);
                offcanvas.show();
            } else {

                initHtmlEditor();
            }

        });

    });
}


/**
 * Handles form submission.
 *
 * @param {Event} event - The form submission event.
 * @param {HTMLFormElement} form - The form element.
 * @param {DataTables.Api|null} datatable - The DataTables API instance (optional).
 * @param {boolean} refresh - Whether to refresh the page after successful submission (optional).
 */
export function handleSubmit(event, form, datatable = null, refresh = false) {
    event.preventDefault();
    //show spinner
    showSpinner(form);

    //remove all is-invalid class
    clearValidationErrors(form);

    let data = new FormData(form);
    fetch(form.action, {
        method: form.method,
        body: data,
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    })
        .then(response => response.json())
        .then(result => {
            if (result.success) {
                showSuccessToast();
                form.reset();
                //refresh datatable if any
                if (datatable) {
                    datatable.ajax.reload();
                }
                if (refresh) {
                    window.location.reload();
                } else {
                    closeOffcanvas();
                    closeModal();
                }

            } else {
                displayValidationErrors(result.errors);
            }
        })
        .catch(error => showErrorToast(error))
        .finally(() => hideSpinner(form));
}


/**
 * Handles the actions triggered by the user.
 *
 * @param {Event} event - The event object representing the user action.
 * @param {HTMLElement} form - The form element associated with the action.
 * @param {DataTable} dataTable - The data table element associated with the action.
 * @param {HTMLElement} offcavas - The offcavas element associated with the action.
 */
export function handleActions(event, form, dataTable, offcavas) {
    let target = event.target;
    if (target.classList.contains('item-delete')) {
        handleDeleteAction(event, target, dataTable);
    }
    if (target.classList.contains('item-edit')) {
        handleEditAction(event, target, form, offcavas);
    }
}

/**
 * Handles the delete action for a specific target element.
 * @param {Event} event - The event object.
 * @param {HTMLElement} target - The target element.
 * @param {DataTable} dataTable - The DataTable object.
 */
const handleDeleteAction = (event, target, dataTable) => {

    event.preventDefault();
    let id = target.dataset.id;
    let model = target.dataset.model;
    if (confirm('Are you sure?')) {
        fetch(
            `/${model}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }
        )
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    showSuccessToast();
                    dataTable.ajax.reload();
                }
            })
            .catch(error => console.log(error))
    }

}

/**
 * Handles the edit action for a specific target element.
 *
 * @param {Event} event - The event object.
 * @param {HTMLElement} target - The target element.
 * @param {HTMLFormElement} form - The form element.
 * @param {HTMLElement} element - The element offcavas or modal associated with the action.
 */
const handleEditAction = (event, target, form, element) => {
    event.preventDefault();
    let id = target.dataset.id;
    let model = target.dataset.model;
    destroyHtmlEditor();
    //clear all is-invalid class
    clearValidationErrors(form);

    //reset form
    form.reset();

    //ajax fetch country from id
    fetch(`/${model}/${id}/edit`,
        {
            method: 'GET',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        }
    )
        .then(response => response.json())
        .then(result => {
            if (result.success) {
                //reset form
                form.reset();

                //remove id input hidden if any
                let oldInputId = document.getElementById('id');
                if (oldInputId) {
                    oldInputId.remove();
                }
                //create input hidden with id
                let inputId = document.createElement('input');
                inputId.setAttribute('type', 'hidden');
                inputId.setAttribute('name', 'id');
                inputId.setAttribute('id', 'id');
                inputId.setAttribute('value', id);
                form.appendChild(inputId);
                //populate form
                for (let [key, value] of Object.entries(result.success)) {
                    let input = document.getElementById(`${key}`);
                    //if checkbox
                    if (input.getAttribute('type') === 'checkbox') {
                        input.checked = value;
                        continue;
                    }
                    input.value = value;
                }
                initHtmlEditor();
                element.classList.contains('offcanvas')
                    ? new bootstrap.Offcanvas(element).show()
                    : new bootstrap.Modal(element).show();
                //change button submit to update
                let submitButton = form.querySelector('.btn-label');
                submitButton.innerText = 'Modifica';
            }
        })
        .catch(error => showErrorToast(error))
}



/**
 * Displays a success toast message.
 *
 * @param {string} [message='Successo! Dati salvati.'] - The message to be displayed in the toast.
 */
export function showSuccessToast(message = 'Successo! Dati salvati.') {
    const toastContainer = document.getElementById('toast-container');
    const toastElement = document.createElement('div');
    toastElement.classList.add('toast', 'show', 'bg-success', 'text-white');
    toastElement.setAttribute('role', 'alert');
    toastElement.setAttribute('aria-live', 'assertive');
    toastElement.setAttribute('aria-atomic', 'true');
    toastElement.setAttribute('data-bs-delay', '6000');
    toastElement.innerHTML = `<div class="toast-body">${message}</div>`;
    toastContainer.appendChild(toastElement);
    const bsToast = new bootstrap.Toast(toastElement);
    bsToast.show();

}


/**
 * Displays an error toast message.
 * @param {string} [error='Errore! Dati non validi'] - The error message to display.
 */
export function showErrorToast(error = 'Errore! Dati non validi') {
    const toastContainer = document.getElementById('toast-container');
    const toastElement = document.createElement('div');
    toastElement.classList.add('toast', 'show', 'bg-danger', 'text-white');
    toastElement.setAttribute('role', 'alert');
    toastElement.setAttribute('aria-live', 'assertive');
    toastElement.setAttribute('aria-atomic', 'true');
    toastElement.innerHTML = `<div class="toast-body">${error}</div>`;
    toastContainer.appendChild(toastElement);
    const bsToast = new bootstrap.Toast(toastElement);
    bsToast.show();

}

/**
 * Closes the offcanvas by clicking the close button.
 */
const closeOffcanvas = () => {
    let closeButton = document.querySelector('.btn-close');
    //if any close it
    if (closeButton) {
        closeButton.click();
    }
}

/**
 * Shows the spinner element for the given form.
 *
 * @param {HTMLFormElement} form - The form element.
 */
export const showSpinner = (form) => {
    let spinner = form.querySelector('.btn-spinner');
    spinner.classList.remove('d-none');
}

/**
 * Hides the spinner element within the specified form.
 *
 * @param {HTMLFormElement} form - The form element containing the spinner.
 */
export const hideSpinner = (form) => {
    let spinner = form.querySelector('.btn-spinner');
    spinner.classList.add('d-none');
}

/**
 * Clears validation errors by removing the 'is-invalid' class from all input elements within a form.
 *
 * @param {HTMLFormElement} form - The form element containing the input elements.
 */
const clearValidationErrors = (form) => {
    form.querySelectorAll('.is-invalid').forEach(input => input.classList.remove('is-invalid'));
}

/**
 * Displays validation errors on the form inputs.
 * @param {Object} errors - The validation errors object.
 */
const displayValidationErrors = (errors) => {
    for (let [key, value] of Object.entries(errors)) {
        let input = document.getElementById(key);
        input.classList.add('is-invalid');
        input.nextElementSibling.innerText = value;
    }
}

/**
 * Applies filters to a data table based on the form inputs.
 *
 * @param {string} formId - The ID of the form element.
 * @param {DataTable} dataTable - The DataTable instance to apply filters to.
 */
export function applyFilters(formId, dataTable) {
    let form = document.getElementById(formId);

    form.addEventListener('submit', (event) => {
        event.preventDefault();
        let formData = new FormData(form);
        for (var pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
            dataTable.column(pair[0] + ':name').search(pair[1]);
        }
        dataTable.draw();
    });

    form.addEventListener('reset', (event) => {
        dataTable.search('').columns().search('').draw();
    });
}

/**
 * Closes the bootstrap modal if it is open.
 */
function closeModal() {
    //if any boostrap modal close it
    let modal = document.querySelector('.modal');
    if (modal) {
        let bsModal = bootstrap.Modal.getInstance(modal);
        bsModal.hide();
    }
}

export function initHtmlEditor() {
    if (document.getElementById('body_it')) {
        initTinyMCE('#body_it');
    }

    if (document.getElementById('body_de')) {
        initTinyMCE('#body_de');
    }
}

function initTinyMCE(selector) {
    tinymce.init({
        selector: selector,
        menubar: false,
        plugins: 'link code',
        branding: false,
        setup: function (editor) {
            editor.ui.registry.addButton('name', {
                text: 'Name',
                onAction: function () {
                    editor.insertContent('{{name}}');
                }
            });

            editor.ui.registry.addButton('order', {
                text: 'Order',
                onAction: function () {
                    editor.insertContent('{{order}}');
                }
            });

            editor.ui.registry.addButton('participant', {
                text: 'Participant',
                onAction: function () {
                    editor.insertContent('{{participant}}');
                }
            });

            editor.ui.registry.addButton('login', {
                text: 'Login',
                onAction: function () {
                    editor.insertContent('{{login}}');
                }
            });

            // Save content on change textarea
            editor.on('change', function () {
                editor.save();
            });
        },
        toolbar: '| undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor | removeformat  link  code | name participant order login |',
    });
}

export function destroyHtmlEditor() {
    //destroy tinymce editor if any
    if (document.getElementById('body_it')) {
        tinymce.remove('#body_it');
    }
    if (document.getElementById('body_de')) {
        tinymce.remove('#body_de');
    }
}