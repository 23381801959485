import * as bootstrap from 'bootstrap';
import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import { initOffcanvas, handleSubmit, handleActions, showSpinner, hideSpinner, showErrorToast, showSuccessToast, applyFilters } from './custom-functions';
import './menu';
import './charts';
import './widgets';
import './orders';
import './export';
import './emails';
import './stats';
window.$ = jQuery;
window.DataTable = DataTable;
window.bootstrap = bootstrap;

'use strict';

/* ===== Enable Bootstrap Popover (on element  ====== */
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

initOffcanvas();

/* ==== Enable Bootstrap Alert ====== */
//var alertList = document.querySelectorAll('.alert')
//alertList.forEach(function (alert) {
//  new bootstrap.Alert(alert)
//});

const alertList = document.querySelectorAll('.alert')
const alerts = [...alertList].map(element => new bootstrap.Alert(element))



$(function () {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');


    /**************
    *
    *  People
    *
    **************/

    let peopleTable = document.getElementById('people-table');
    if (peopleTable) {
        //let managePersonForm = document.getElementById('manage-event-form');

        //init datatable
        const peopleDataTable = $('#people-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/people`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'fullname', name: 'fullname' },
                { data: 'lastname', name: 'lastname', visible: false },
                { data: 'firstname', name: 'firstname', visible: false },
                { data: 'email', name: 'email', searchable: true },
                { data: 'phone', name: 'phone', searchable: true },
                { data: 'dob', name: 'dob', searchable: false },
                { data: 'address', name: 'address', searchable: true },
                { data: 'city', name: 'city', searchable: true },
                { data: 'zip', name: 'zip', searchable: true },
                { data: 'state', name: 'state', searchable: true },
                { data: 'country', name: 'country', searchable: true },
                { data: 'total_orders', name: 'total_orders', searchable: false },
                { data: 'total_amount', name: 'total_amount', searchable: false },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            order: [
                [1, "asc"]
            ]
        });

    }

    /**************
    *
    *  Places
    *
    **************/

    let placesTable = document.getElementById('places-table');
    if (placesTable) {
        //let manageplaceForm = document.getElementById('manage-place-form');
        //init datatable
        const placesDataTable = $('#places-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/places`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'name', name: 'name', visible: true },
                { data: 'address', name: 'address', searchable: true },
                { data: 'city', name: 'city', searchable: true },
                { data: 'zip', name: 'zip', searchable: true },
                { data: 'province', name: 'province', searchable: true },
                { data: 'country', name: 'country', searchable: true },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            order: [
                [1, "asc"]
            ]
        });
    }

    /**************
    *
    *  Events
    *
    **************/

    let eventsTable = document.getElementById('events-table');
    if (eventsTable) {
        // let manageEventForm = document.getElementById('manage-event-form');

        //init datatable
        const eventsDataTable = $('#events-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/events`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'name', name: 'name', visible: true },
                { data: 'start_date', name: 'start_date', searchable: true },
                { data: 'end_date', name: 'end_date', searchable: true },
                { data: 'timezone_abbr', name: 'timezone_abbr', searchable: false },
                { data: 'place_name', name: 'places.name', searchable: true },
                { data: 'serie_name', name: 'series.name', searchable: true },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            order: [
                [3, "desc"]
            ]
        });

        // filtra
        applyFilters('events-filters-form', eventsDataTable);

    }

    /**************
    *
    *  Orders
    *
    **************/

    let ordersTable = document.getElementById('orders-table');
    if (ordersTable) {
        //init datatable
        const ordersDataTable = $('#orders-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/orders`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'wp_post_id', name: 'wp_post_id', searchable: true },
                { data: 'created_at', name: 'created_at', searchable: false },
                { data: 'fullname', name: 'people.fullname', visible: true },
                { data: 'lastname', name: 'people.lastname', visible: false },
                { data: 'firstname', name: 'people.firstname', visible: false },
                { data: 'billing_email', name: 'billing_email', searchable: true },
                { data: 'amount', name: 'amount', searchable: true },
                { data: 'payment_method', name: 'payment_method', searchable: true },
                { data: 'receipt_status', name: 'receipt_status', searchable: true },
                { data: 'status', name: 'status', searchable: true },
                { data: 'updated_at', name: 'updated_at', searchable: false },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            order: [
                [2, "desc"]
            ]
        });
        applyFilters('orders-filters-form', ordersDataTable);
    }


    /**************
    *
    *  Participants
    *
    **************/

    let participantsTable = document.getElementById('participants-table');
    if (participantsTable) {
        //let manageParticipantForm = document.getElementById('manage-participant-form');

        //init datatable
        const participantsDataTable = $('#participants-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/participants`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'lastname', name: 'lastname', orderable: true, visible: true },
                { data: 'firstname', name: 'firstname', orderable: true, visible: true },
                { data: 'dob', name: 'dob', searchable: false },
                { data: 'gender', name: 'gender', searchable: true },
                { data: 'role', name: 'role', searchable: true },
                { data: 'size', name: 'size', searchable: true },
                { data: 'language', name: 'language', searchable: true },
                { data: 'event_name', name: 'events.name', searchable: true },
                { data: 'order_id', name: 'orders.wp_post_id', searchable: true },
                { data: 'order_status', name: 'orders.status', searchable: true },
                { data: 'medical_certificate_status', name: 'medical_certificate_status', orderable: true, searchable: true },
                { data: 'person_fullname', name: 'people.fullname', searchable: true },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            participant: [
                [1, "desc"]
            ]
        });

        applyFilters('participants-filters-form', participantsDataTable);
    }

    /** Person participants */

    let personParticipantsTable = document.getElementById('person-participants-table');
    if (personParticipantsTable) {
        const id = personParticipantsTable.getAttribute('data-person');
        const personParticipantsDataTable = $('#person-participants-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/people/${id}`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'order_id', name: 'orders.id', orderable: true, visible: true },
                { data: 'fullname', name: 'fullname', orderable: true, visible: true, searchable: false },
                { data: 'lastname', name: 'lastname', orderable: true, visible: false, searchable: true },
                { data: 'firstname', name: 'firstname', orderable: true, visible: false, searchable: true },
                { data: 'event_name', name: 'events.name', searchable: true },
                { data: 'role', name: 'role', searchable: true },
                { data: 'date', name: 'date', searchable: false },

                { data: 'place_name', name: 'places.name', searchable: true },
                { data: 'order_status', name: 'orders.status', searchable: true },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            participant: [
                [1, "desc"]
            ]
        });


    }

    let personOrdersTable = document.getElementById('person-orders-table');
    if (personOrdersTable) {
        const id = personOrdersTable.getAttribute('data-person');
        //init datatable
        const personOrdersDataTable = $('#person-orders-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/people/${id}/orders`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'order_id', name: 'orders.wp_post_id', orderable: true, visible: true },
                { data: 'product_names', name: 'product_names', orderable: true, visible: true, searchable: false },
                { data: 'payment_method', name: 'payment_method', orderable: true, searchable: true },
                { data: 'created_at', name: 'created_at', orderable: true, searchable: true },
                { data: 'status', name: 'orders.status', searchable: true },
                { data: 'amount', name: 'amount', searchable: true },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            participant: [
                [1, "desc"]
            ]
        });


    }

    /**************
    *
    *  Products
    *
    **************/

    let productsTable = document.getElementById('products-table');
    if (productsTable) {
        //let manageProductForm = document.getElementById('manage-product-form');

        //init datatable
        const productsDataTable = $('#products-table').DataTable({
            processing: true,
            serverSide: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
                'processing': 'Caricamento in corso...',
            },
            ajax: `/products`,

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'name', name: 'name', orderable: true, visible: true },
                { data: 'price', name: 'price', searchable: true },
                { data: 'start_date', name: 'start_date', searchable: false },
                { data: 'end_date', name: 'end_date', searchable: false },
                { data: 'sku', name: 'sku', searchable: true },
                { data: 'capacity', name: 'capacity', searchable: true },
                { data: 'stock', name: 'stock', searchable: true },
                { data: 'total_sales', name: 'total_sales', searchable: true },
                { data: 'stock_status', name: 'stock_status', searchable: true },
                { data: 'event_name', name: 'events.name', searchable: true },
                { data: 'action', name: 'action', orderable: false, searchable: false },
            ],
            pageLength: 30,
            //set 30 per page
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10, 30, 50, 100, "All"]
            ],

            // language: {
            //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
            // },
            product: [
                [1, "desc"]
            ]
        });

    }

    $('#event-participants-table').DataTable();
});


/**************
 * edit participant
 * 
 * */

let currentValue = null;
//get all .edit-participant
let editParticipants = document.querySelectorAll('.edit-participant');
if (editParticipants.length > 0) {
    editParticipants.forEach(editParticipant => {
        editParticipant.addEventListener('click', (e) => {
            e.preventDefault();
            //get data-field
            let field = editParticipant.getAttribute('data-field');
            //get data-id
            let id = editParticipant.getAttribute('data-id');
            //get elment with data-field
            let elements = document.querySelectorAll(`[data-field="${field}"]`);
            //remove dnone class from elements
            elements.forEach(element => {
                element.classList.remove('d-none');
            });

            editParticipant.classList.add('d-none');

            //get element with id field-id
            let fieldId = document.getElementById(`${field}-${id}`);
            currentValue = fieldId.value;
            //remove disabled from fieldId
            fieldId.removeAttribute('disabled');
        });
    });
}

//get all .clear-participant
let clearParticipants = document.querySelectorAll('.clear-participant');
if (clearParticipants.length > 0) {
    clearParticipants.forEach(clearParticipant => {
        clearParticipant.addEventListener('click', (e) => {
            e.preventDefault();
            //get data-field
            let field = clearParticipant.getAttribute('data-field');
            //get data-id
            let id = clearParticipant.getAttribute('data-id');
            //get element with data-field
            let elements = document.querySelectorAll(`[data-field="${field}"]`);
            //remove dnone class from elements
            elements.forEach(element => {
                element.classList.add('d-none');
            });
            //get element with id field-id
            let fieldId = document.getElementById(`${field}-${id}`);
            //add disabled to fieldId
            fieldId.setAttribute('disabled', 'disabled');
            //set value to fieldId
            fieldId.value = currentValue;
            //get editParticipant
            let editParticipant = elements[0];
            editParticipant.classList.remove('d-none');
        });
    });
}

//get all .save-participant
let saveParticipants = document.querySelectorAll('.save-participant');
if (saveParticipants.length > 0) {
    saveParticipants.forEach(saveParticipant => {
        saveParticipant.addEventListener('click', (e) => {
            e.preventDefault();
            var spinner = document.getElementById('fixed-spinner');
            spinner.classList.remove('d-none');
            let id = saveParticipant.getAttribute('data-id');
            let field = saveParticipant.getAttribute('data-field');
            //input field
            let inputField = document.getElementById(`${field}-${id}`);
            let inputValue = inputField.value;

            const data = {
                field: field,
                value: inputValue
            };

            const csrfToken = document.querySelector('meta[name="csrf-token"]')
                .getAttribute('content');
            const url = `/participants/${id}/ajax-update`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        //hide fixed spinner
                        spinner.classList.add('d-none');
                        showSuccessToast(); // Show success toast

                        inputField.setAttribute('disabled', true);

                        const clearButton = document.querySelector(
                            `.clear-participant[data-field="${field}"]`);
                        const editButton = document.querySelector(
                            `.edit-participant[data-field="${field}"]`);
                        const saveButton = document.querySelector(
                            `.save-participant[data-field="${field}"]`);

                        clearButton.classList.add('d-none');
                        saveButton.classList.add('d-none');
                        editButton.classList.remove('d-none');
                    } else {
                        spinner.classList.add('d-none');
                        showErrorToast(); // Show error toast
                    }

                })
                .catch(error => {
                    showErrorToast(); // Show error toast
                    const clearButton = document.querySelector(
                        `.clear-participant[data-field="${field}"]`);
                    const editButton = document.querySelector(
                        `.edit-participant[data-field="${field}"]`);
                    const saveButton = document.querySelector(
                        `.save-participant[data-field="${field}"]`);

                    clearButton.classList.add('d-none');
                    saveButton.classList.add('d-none');
                    editButton.classList.remove('d-none');
                })
        });
    });
}


let contactsStore = document.getElementById('contacts-store');
if (contactsStore) {
    contactsStore.addEventListener('submit', (event) => {
        handleSubmit(event, contactsStore, null, true);
    });

}

//get all .delete-contact
let deleteContacts = document.querySelectorAll('.delete-contact');
if (deleteContacts.length > 0) {
    deleteContacts.forEach(deleteContact => {
        deleteContact.addEventListener('click', (e) => {
            e.preventDefault();
            //get data-id
            let id = deleteContact.getAttribute('data-id');
            let participant = deleteContact.getAttribute('data-participant');
            //send fetch request
            fetch(`/participants/${participant}/contacts/${id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        //remove row from table
                        let row = deleteContact.closest('div');
                        row.remove();
                        showSuccessToast();
                    } else {
                        showErrorToast();
                    }
                })
                .catch(error => showErrorToast());
        });
    });
}

const uploadForm = document.getElementById('upload-form');
if (uploadForm) {


    uploadForm.addEventListener('submit', function (e) {
        e.preventDefault();
        //if empty file
        if (document.getElementById('formFile').files.length === 0) {
            return;
        }

        let formData = new FormData();
        // get value where name="partecipant_id"
        formData.append('file', document.getElementById('formFile').files[0]);
        showSpinner(uploadForm);

        fetch(uploadForm.action, {
            method: uploadForm.method,
            body: formData,
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.success) {
                    showSuccessToast()
                    uploadForm.reset();
                    //refresh page
                    window.location.reload();
                } else {
                    showErrorToast();
                }
            })
            .catch(error => showErrorToast())
            .finally(() => hideSpinner(uploadForm));
    });
}

//delete file

const deleteFileForm = document.getElementById('delete-file-form');
if (deleteFileForm) {
    deleteFileForm.addEventListener('submit', function (e) {
        showSpinner(deleteFileForm);
    });
}
const approveFileForm = document.getElementById('approve-file-form');
if (approveFileForm) {
    approveFileForm.addEventListener('submit', function (e) {
        showSpinner(approveFileForm);
    });
}


/**************
*
*  Users
*
**************/

let userTable = document.getElementById('users-table');
if (userTable) {
    let manageUserForm = document.getElementById('manage-user-form');
    let userOffcanvasForm = document.getElementById('user-offcanvas-form');
    //init datatable
    const userDataTable = $('#users-table').DataTable({
        processing: true,
        serverSide: true,
        ajax: `/users`,
        columns: [
            { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
            { data: 'name', name: 'name' },
            { data: 'email', name: 'email' },
            { data: 'role', name: 'role', visible: true, orderable: true, searchable: false },
            { data: 'status', name: 'status' },
            { data: 'action', name: 'action', orderable: false, searchable: false },
        ],
        pageLength: 30,
        //set 30 per page
        lengthMenu: [
            [10, 30, 50, 100, -1],
            [10, 30, 50, 100, "All"]
        ],

        // language: {
        //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
        // },
        order: [
            [0, "asc"]
        ]
    });
    // on form submit
    manageUserForm.addEventListener('submit', (event) => {
        handleSubmit(event, manageUserForm, userDataTable);
    });

    userTable.addEventListener('click', (event) => {
        handleActions(event, manageUserForm, userDataTable, userOffcanvasForm);
    });

}

/**************
*
*  email templates
*
**************/

let emailTemplatesTable = document.getElementById('email-templates-table');
if (emailTemplatesTable) {
    let manageEmailTemplatesForm = document.getElementById('manage-email-template-form');
    let  emailTemplatesOffcanvasForm = document.getElementById('email-template-offcanvas-form');
    //init datatable
    const emailTemplatesDataTable = $('#email-templates-table').DataTable({
        processing: true,
        serverSide: true,
        ajax: `/email-templates`,
        columns: [
            { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
            { data: 'name', name: 'name' },
            { data: 'subject_it', name: 'subject_it' },
            { data: 'subject_de', name: 'subject_de' },
            { data: 'updated_at', name: 'updated_at' },
            { data: 'created_at', name: 'created_at' },
            { data: 'action', name: 'action', orderable: false, searchable: false },
        ],
        pageLength: 30,
        //set 30 per page
        lengthMenu: [
            [10, 30, 50, 100, -1],
            [10, 30, 50, 100, "All"]
        ],

        // language: {
        //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
        // },
        order: [
            [0, "asc"]
        ]
    });
    // on form submit
    manageEmailTemplatesForm.addEventListener('submit', (event) => {
        handleSubmit(event, manageEmailTemplatesForm, emailTemplatesDataTable);
    });

    emailTemplatesTable.addEventListener('click', (event) => {
        handleActions(event, manageEmailTemplatesForm, emailTemplatesDataTable, emailTemplatesOffcanvasForm);
    });

}


/**************
*
*  Series
*
**************/

let serieTable = document.getElementById('series-table');
if (serieTable) {
    let manageSerieForm = document.getElementById('manage-serie-form');
    let serieOffcanvasForm = document.getElementById('serie-offcanvas-form');
    //init datatable
    const serieDataTable = $('#series-table').DataTable({
        processing: true,
        serverSide: true,
        ajax: `/series`,
        columns: [
            { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
            { data: 'name', name: 'name' },
            { data: 'is_current', name: 'is_current' },
            { data: 'wp_post_id', name: 'wp_post_id' },
            { data: 'action', name: 'action', orderable: false, searchable: false },
        ],
        pageLength: 30,
        //set 30 per page
        lengthMenu: [
            [10, 30, 50, 100, -1],
            [10, 30, 50, 100, "All"]
        ],

        // language: {
        //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
        // },
        order: [
            [0, "asc"]
        ]
    });
    // on form submit
    manageSerieForm.addEventListener('submit', (event) => {
        handleSubmit(event, manageSerieForm, serieDataTable);
    });

    serieTable.addEventListener('click', (event) => {
        handleActions(event, manageSerieForm, serieDataTable, serieOffcanvasForm);
    });

}


/**************
*
*  Sizes
*
**************/

let sizeTable = document.getElementById('sizes-table');
if (sizeTable) {
    //init datatable
    const sizeDataTable = $('#sizes-table').DataTable({
        "footerCallback": function (row, data, start, end, display) {
            var api = this.api(), data;

            // converting to interger to find total
            var intVal = function (i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                        i : 0;
            };

            // computing column Total of the complete result 
            var players = api
                .column(1)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var yyxs = api
                .column(2)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var yxs = api
                .column(3)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var xxs = api
                .column(4)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var xs = api
                .column(5)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var s = api
                .column(6)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var m = api
                .column(7)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var l = api
                .column(8)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            // Update footer by showing the total with the reference of the column index 
            $(api.column(0).footer()).html('Tot:');
            $(api.column(1).footer()).html(players);
            $(api.column(2).footer()).html(yyxs);
            $(api.column(3).footer()).html(yxs);
            $(api.column(4).footer()).html(xxs);
            $(api.column(5).footer()).html(xs);
            $(api.column(6).footer()).html(s);
            $(api.column(7).footer()).html(m);
            $(api.column(8).footer()).html(l);
        },
        processing: true,
        serverSide: true,
        ajax: `/sizes`,
        columns: [

            { data: 'event_name', name: 'events.name' },
            { data: 'total_participants', name: 'total_participants', searchable: false, orderable: false },
            { data: 'l', name: 'l', searchable: false, orderable: false },
            { data: 'm', name: 'm', searchable: false, orderable: false },
            { data: 's', name: 's', searchable: false, orderable: false },
            { data: 'xs', name: 'xs', searchable: false, orderable: false },
            { data: 'xxs', name: 'xxs', searchable: false, orderable: false },
            { data: 'yxs', name: 'yxs', searchable: false, orderable: false },
            { data: 'yyxs', name: 'yyxs', searchable: false, orderable: false },
        ],
        pageLength: 30,
        //set 30 per page
        lengthMenu: [
            [10, 30, 50, 100, -1],
            [10, 30, 50, 100, "All"]
        ],

        // language: {
        //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
        // },
        order: [
            [0, "asc"]
        ]
    });
    applyFilters('sizes-filters-form', sizeDataTable);
}

/**************
*
*  Sizes Goalkeepers
*
**************/

let sizeGkTable = document.getElementById('sizes-goalkeepers-table');
if (sizeGkTable) {
    //init datatable
    const sizeGkDataTable = $('#sizes-goalkeepers-table').DataTable({
        "footerCallback": function (row, data, start, end, display) {
            var api = this.api(), data;

            // converting to interger to find total
            var intVal = function (i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                        i : 0;
            };

            // computing column Total of the complete result 
            var players = api
                .column(1)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var yyxs = api
                .column(2)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var yxs = api
                .column(3)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var xxs = api
                .column(4)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var xs = api
                .column(5)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var s = api
                .column(6)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var m = api
                .column(7)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var l = api
                .column(8)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            // Update footer by showing the total with the reference of the column index 
            $(api.column(0).footer()).html('Tot:');
            $(api.column(1).footer()).html(players);
            $(api.column(2).footer()).html(yyxs);
            $(api.column(3).footer()).html(yxs);
            $(api.column(4).footer()).html(xxs);
            $(api.column(5).footer()).html(xs);
            $(api.column(6).footer()).html(s);
            $(api.column(7).footer()).html(m);
            $(api.column(8).footer()).html(l);
        },
        processing: true,
        serverSide: true,
        ajax: `/sizes-goalkeepers`,
        columns: [

            { data: 'event_name', name: 'events.name' },
            { data: 'total_participants', name: 'total_participants', searchable: false, orderable: false },
            { data: 'l', name: 'l', searchable: false, orderable: false },
            { data: 'm', name: 'm', searchable: false, orderable: false },
            { data: 's', name: 's', searchable: false, orderable: false },
            { data: 'xs', name: 'xs', searchable: false, orderable: false },
            { data: 'xxs', name: 'xxs', searchable: false, orderable: false },
            { data: 'yxs', name: 'yxs', searchable: false, orderable: false },
            { data: 'yyxs', name: 'yyxs', searchable: false, orderable: false },
        ],
        pageLength: 30,
        //set 30 per page
        lengthMenu: [
            [10, 30, 50, 100, -1],
            [10, 30, 50, 100, "All"]
        ],

        // language: {
        //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
        // },
        order: [
            [0, "asc"]
        ]
    });
    applyFilters('sizes-goalkeepers-filters-form', sizeGkDataTable);
}

/**************
*
*  Sizes
*
**************/

let glovesSizeTable = document.getElementById('gloves-sizes-table');
if (glovesSizeTable) {
    //init datatable
    const glovesSizeDataTable = $('#gloves-sizes-table').DataTable({
        "footerCallback": function (row, data, start, end, display) {
            var api = this.api(), data;

            // converting to interger to find total
            var intVal = function (i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                        i : 0;
            };

            // computing column Total of the complete result 
            var goalkeepers = api
                .column(1)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs5 = api
                .column(2)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var gs6 = api
                .column(3)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var gs7 = api
                .column(4)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs75 = api
                .column(5)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var gs8 = api
                .column(6)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            var gs85 = api
                .column(7)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs9 = api
                .column(8)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs95 = api
                .column(9)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs10 = api
                .column(10)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs105 = api
                .column(11)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);
            var gs11 = api
                .column(12)
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            // Update footer by showing the total with the reference of the column index 
            $(api.column(0).footer()).html('Tot:');
            $(api.column(1).footer()).html(goalkeepers);
            $(api.column(2).footer()).html(gs5);
            $(api.column(3).footer()).html(gs6);
            $(api.column(4).footer()).html(gs7);
            $(api.column(5).footer()).html(gs75);
            $(api.column(6).footer()).html(gs8);
            $(api.column(7).footer()).html(gs85);
            $(api.column(8).footer()).html(gs9);
            $(api.column(9).footer()).html(gs95);
            $(api.column(10).footer()).html(gs10);
            $(api.column(11).footer()).html(gs105);
            $(api.column(12).footer()).html(gs11);
        },
        processing: true,
        serverSide: true,
        ajax: `/gloves-sizes`,
        columns: [

            { data: 'event_name', name: 'events.name' },
            { data: 'total_participants', name: 'total_participants', searchable: false, orderable: false },
            { data: 'gs5', name: 'gs5', searchable: false, orderable: false },
            { data: 'gs6', name: 'gs6', searchable: false, orderable: false },
            { data: 'gs7', name: 'gs7', searchable: false, orderable: false },
            { data: 'gs75', name: 'gs75', searchable: false, orderable: false },
            { data: 'gs8', name: 'gs8', searchable: false, orderable: false },
            { data: 'gs85', name: 'gs85', searchable: false, orderable: false },
            { data: 'gs9', name: 'gs9', searchable: false, orderable: false },
            { data: 'gs95', name: 'gs95', searchable: false, orderable: false },
            { data: 'gs10', name: 'gs10', searchable: false, orderable: false },
            { data: 'gs105', name: 'gs105', searchable: false, orderable: false },
            { data: 'gs11', name: 'gs11', searchable: false, orderable: false },
        ],
        pageLength: 30,
        //set 30 per page
        lengthMenu: [
            [10, 30, 50, 100, -1],
            [10, 30, 50, 100, "All"]
        ],

        // language: {
        //     url: "{{ asset('js/datatables/i18n/italian.json') }}"
        // },
        order: [
            [0, "asc"]
        ]
    });
    applyFilters('gloves-sizes-filters-form', glovesSizeDataTable);
}

//get participant-email-form id
let participantEmailForm = document.getElementById('participant-email-form');
if (participantEmailForm) {
    participantEmailForm.addEventListener('submit', (event) => {
        handleSubmit(event, participantEmailForm);
    });
}